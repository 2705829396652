
.hero {
    position: relative;
//background-image: url(images/img_1.png);
    background-size: 100% 100%;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
}

.container {

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
//padding-top: calc(var(--mantine-spacing-xl) * 3);
//padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    z-index: 1;
    position: relative;

    @media (max-width: $mantine-breakpoint-sm) {
        height: rem(500px);
        padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    }
}

.title {
    color: var(--mantine-color-white);
    font-size: rem(60px);
    font-weight: 800;
    line-height: 1.1;
//max-width: rem(600px);

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: rem(40px);
        line-height: 1.2;
    }

    @media (max-width: $mantine-breakpoint-xs) {
        font-size: rem(28px);
        line-height: 1.3;
    }
}

.description {
    color: var(--mantine-color-white);
    max-width: rem(600px);

    @media (max-width: $mantine-breakpoint-sm) {
        max-width: 100%;
        font-size: var(--mantine-font-size-sm);
    }
}

.control {
    margin-top: calc(var(--mantine-spacing-xl) * 1.5);
    @media (max-width: $mantine-breakpoint-sm) {
        width: 100%;
    }
}