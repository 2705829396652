
.title {
    font-size: rem(34px);
    font-weight: 900;
    @media (max-width: $mantine-breakpoint-sm) {
        font-size: rem(24px);
    }
}

.description {
    max-width: rem(600px);
    margin: auto;

    &::after {
        content: '';
        display: block;
        background-color: var(--mantine-color-white);
        width: rem(45px);
        height: rem(2px);
        margin-top: var(--mantine-spacing-sm);
        margin-left: auto;
        margin-right: auto;
    }
}

.card {
    border: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-4));
}

.cardTitle {
    &::after {
        content: '';
        display: block;
        background-color: var(--mantine-color-white);
        width: rem(45px);
        height: rem(2px);
        margin-top: var(--mantine-spacing-sm);
    }
}