.inner {
    display: flex;
    justify-content: space-between;
    padding-top: calc(var(--mantine-spacing-xl) * 0);
    padding-bottom: calc(var(--mantine-spacing-xl) * 0);
    //padding-left: 100px;

}

.content {
    //max-width: rem(540px);
    height: 100%;
    //margin-right: calc(var(--mantine-spacing-xl) * 1);
    //margin-left: calc(var(--mantine-spacing-xl) * 1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    @media (max-width: $mantine-breakpoint-md) {
        max-width: 100%;
        margin-right: 0;
    }
}

.title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family: Greycliff CF, var(--mantine-font-family);
    font-size: rem(40px);
    line-height: 1.2;
    font-weight: 800;

    @media (max-width: $mantine-breakpoint-xs) {
        font-size: rem(28px);
    }
}

.control {
    @media (max-width: $mantine-breakpoint-xs) {
        flex: 1;
    }
}

.image {
    //width: rem(900px);
    //height: rem(290px);


}

.highlight {
    position: relative;
    background-color: var(--mantine-color-blue-light);
    border-radius: var(--mantine-radius-sm);
    padding: rem(4px) rem(0px) rem(4px) rem(0px);
}

/* FeatureText.module.css */
.reverse {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    //padding-right: 100px;
    //padding-left: 0px !important;

}
